import userApi from '@/api/users'

export default {
  state: {
    logopedistDetails: {}
  },

  mutations: {
    setLogopedistDetails(state, logopedistDetails) {
      state.logopedistDetails = logopedistDetails
    }
  },

  actions: {
    async getLogopedist({ commit }, pedagogId) {
      try {
        const { data } = await userApi.getLogopedist({
          filter: {
            id: pedagogId
          }
        })

        if (data?.success) {
          commit('setLogopedistDetails', data.result.rows[0])
        }
      } catch (error) {
        console.warn(error)
      }
    }
  },

  getters: {
    logopedistDetails: ({ logopedistDetails }) => logopedistDetails
  }
}

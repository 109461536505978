import Vue from 'vue'

export default {
  async overallCount(roleId) {
    const { data } = await Vue.axios.get(`/dashboard/users/count/${roleId}`)
    return data
  },
  async getParents({ pagination, order, filter, string = '' }, { cancelToken }) {
    return Vue.axios.post(
      '/dashboard/users',
      {
        pagination,
        order,
        filter: {
          ...filter,
          roleId: 1
        },
        scan: {
          fields: ['firstName', 'lastName', 'middleName', 'email', 'phone', 'id'],
          value: string
        }
      },
      { cancelToken }
    )
  },
  // findUsers({ value, filter, pagination }, { cancelToken }) {
  //   return Vue.axios.post(
  //     `/dashboard/users/match/${value}`,
  //     {
  //       filter,
  //       pagination
  //     },
  //     { cancelToken }
  //   )
  // },
  getParent(filter) {
    return Vue.axios.post('/dashboard/users', filter)
  },
  updateUser(id, data) {
    return Vue.axios.patch(`/dashboard/users/${id}`, data)
  },

  getStudents({ pagination, order, scan = {} }, { cancelToken }) {
    return Vue.axios.post(
      '/dashboard/users',
      {
        pagination,
        order,
        filter: {
          roleId: 2
        },
        scan
      },
      { cancelToken }
    )
  },

  getTeachers(offset, value) {
    return Vue.axios.post('/dashboard/teachers', {
      pagination: {
        offset,
        limit: 10
      },
      scan: { fields: ['firstName', 'lastName', 'middleName', 'email', 'phone', 'id'], value }
    })
  },

  getTeachersWithLimit({ offset, limit = 10 }, value, filter = {}) {
    return Vue.axios.post('/dashboard/teachers/listTeachers', {
      pagination: {
        offset,
        limit
      },
      scan: { fields: ['firstName', 'lastName', 'middleName', 'email', 'phone', 'id'], value },
      needFullData: true,
      filter
    })
  },
  getAllTeachers() {
    return Vue.axios.post('/dashboard/teachers', {})
  },
  getAllUser() {
    return Vue.axios.post('/dashboard/users', {})
  },
  getTeacher(filter) {
    return Vue.axios.post('/dashboard/teachers', filter)
  },
  getTeacherReport(start, end) {
    return Vue.axios.post(
      '/dashboard/reports/earnings/teachers',
      { start, end },
      { responseType: 'blob' }
    )
  },

  getReportListPedagogs(data) {
    return Vue.axios.post('/dashboard/reports/list/teachers', data)
  },
  getReportListPedagogsFile(data) {
    return Vue.axios.post('/dashboard/reports/list/teachers/xlsx', data, {
      responseType: 'blob'
    })
  },

  getReportListUsers(data) {
    return Vue.axios.post('/dashboard/reports/list/all', data)
  },
  getReportListUsersFile(data) {
    return Vue.axios.post('/dashboard/reports/list/all/xlsx', data, {
      responseType: 'blob'
    })
  },

  getLogopedists(offset, value) {
    return Vue.axios.post('/dashboard/logopedists', {
      pagination: {
        offset,
        limit: 10
      },
      scan: { fields: ['firstName', 'lastName', 'middleName', 'email', 'phone', 'id'], value }
    })
  },

  getLogopedistsWithLimit({ offset, limit = 10 }, value, filter = {}) {
    return Vue.axios.post('/dashboard/logopedists/listLogopedists', {
      pagination: {
        offset,
        limit
      },
      scan: { fields: ['firstName', 'lastName', 'middleName', 'email', 'phone', 'id'], value },
      needFullData: true,
      filter
    })
  },
  async getLogopedistTypes() {
    return (await Vue.axios.get('/dashboard/logopedists/types')).data
  },
  getLogopedist(filter) {
    return Vue.axios.post('/dashboard/logopedists', filter)
  },

  getPreschoolTeachers(offset, value) {
    return Vue.axios.post('/dashboard/preschoolTeachers', {
      pagination: {
        offset,
        limit: 10
      },
      scan: { fields: ['firstName', 'lastName', 'middleName', 'email', 'phone', 'id'], value }
    })
  },

  getPreschoolTeachersWithLimit({ offset, limit = 10 }, value, filter = {}) {
    return Vue.axios.post('/dashboard/preschoolTeachers/listPreschoolTeachers', {
      pagination: {
        offset,
        limit
      },
      scan: { fields: ['firstName', 'lastName', 'middleName', 'email', 'phone', 'id'], value },
      needFullData: true,
      filter
    })
  },
  async getPreschoolTeacherTypes() {
    return (await Vue.axios.get('/dashboard/preschoolTeachers/types')).data
  },
  getPreschoolTeacher(filter) {
    return Vue.axios.post('/dashboard/preschoolTeachers', filter)
  },

  // getElementaryTeachers(offset) {
  //   return Vue.axios.post('/dashboard/preschoolTeachers', {
  //     pagination: {
  //       offset,
  //       limit: 10
  //     }
  //   })
  // },
  // getElementaryTeacher(filter) {
  //   return Vue.axios.post('/dashboard/preschoolTeachers', filter)
  // },

  checkIsSelfEmployed(id) {
    return Vue.axios.get(`/dashboard/teachers/status/${id}`)
  },
  async addMinutes({ userId, minutes, logopedisticMinutes }){
    return (await Vue.axios.post(`/dashboard/users/${userId}/account`, { minutes, logopedisticMinutes })).data
  },
  checkIsSelfEmployedPreschool(id) {
    return Vue.axios.get(`/dashboard/preschoolTeachers/status/${id}`)
  },
  setIsVerified(id) {
    return Vue.axios.patch(`/dashboard/teachers/${id}`)
  },
  setIsDeactived(id) {
    return Vue.axios.patch(`/dashboard/teachers/deactived/${id}`)
  },
  getPedagogFiles(userId) {
    return Vue.axios.get(`/dashboard/users/documents/list/${userId}`)
  },
  downloadPedagogFile(id) {
    return Vue.axios.get(`/dashboard/users/documents/${id}`, { responseType: 'blob' })
  },
  async deleteUser({ userId }){
    return (await Vue.axios.delete(`/dashboard/users/${userId}`)).data
  },
  async updateEducation({ route, ...data }) {
    return (await Vue.axios.post(`/dashboard/${route}/updateEducation`, data)).data
  },
  async createBitClient(data) {
    return (await Vue.axios.post(`/dashboard/users/bit`, data)).data
  },
  async createParent(data) {
    return (await Vue.axios.post(`/dashboard/users/parent/create`, data)).data
  },
  async setPinCode(data) {
    return (await Vue.axios.post(`/dashboard/users/parent/setPinCode`, data)).data
  },
  checkUserPhoneOrEmail(data) {
    return Vue.axios.post('/dashboard/users/check', data)
  },
  async updateTeacher(data){
    return Vue.axios.put(`/dashboard/teachers/${data.id}`, data)
  },
  deleteFile(data){
    return Vue.axios.delete(`/dashboard/users/documents/${data}`)
  },
  setFile(data, typeId = null) {
    return Vue.axios.post(`/dashboard/users/documents/${typeId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  async userInfo(userId) {
    return (await Vue.axios.get(`/dashboard/orders/logopedistic/users-info/${userId}`)).data
  },
  async getAvatar(id) {
    return await Vue.axios.get(`/dashboard/users/avatar/${id}`, { responseType: 'arraybuffer' })
  }
}
